import React, { useContext } from "react";
import AuthContext from "./context/AuthContext/authContext";
import AuthLoadingLayout from "./layouts/AuthLoadingLayout";
import MainLayout from "./layouts/MainLayout";

function App() {
  const { isAuthLoading, isAuthenticated, isAuthError } = useContext(AuthContext);
  if (isAuthLoading) {
    return <AuthLoadingLayout />;
  }
  return <MainLayout />;
}

export default App;
