import React, { useMemo } from "react";
import { useTable, useFlexLayout, useSortBy, useGlobalFilter, useAsyncDebounce } from "react-table";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";

const RevenueTable = ({ columns, data, filterText }) => {
  const defaultColumn = React.useMemo(() => ({ minWidth: 30, width: 150, maxWidth: 200 }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{ id: "event_date", desc: true }],
        // globalFilter: useMemo(() => "", []),
      },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy
  );

  if (state.globalFilter !== filterText) {
    setGlobalFilter(filterText);
  }

  // We remove the minWidth styling from table props as we're using
  // scroll overflow on the table in this case instead.
  const tableProps = getTableProps();
  Reflect.deleteProperty(tableProps.style, "minWidth");

  return (
    <div
      id="table"
      className="bg-white min-w-0 max-w-full overflow-x-auto text-xs shadow-lg"
      {...tableProps}
    >
      <div id="thead" className="text-white">
        {headerGroups.map((headerGroup) => {
          // Again... Delete minWidth
          const headerGroupProps = headerGroup.getHeaderGroupProps();
          Reflect.deleteProperty(headerGroupProps.style, "minWidth");
          return (
            <div id="tr" className="bg-tcaddie_green w-max tblmax:w-full" {...headerGroupProps}>
              {headerGroup.headers.map((column) => (
                <div
                  id="th"
                  className={`m-0 p-2 w-max text-xs font-medium uppercase ${
                    column.canSort && column.isSorted ? "shadow-selected-header" : ""
                  } ${column.canSort ? "hover:shadow-selected-header" : ""}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  {/*{column.isSorted ? (*/}
                  {/*  column.isSortedDesc ? (*/}
                  {/*    <div className="flex justify-center items-center">*/}
                  {/*      {column.render("Header")}{" "}*/}
                  {/*      <ChevronDownIcon className="w-3 h-3 ml-2 inline" />*/}
                  {/*    </div>*/}
                  {/*  ) : (*/}
                  {/*    <div className="flex justify-center items-center">*/}
                  {/*      {column.render("Header")} <ChevronUpIcon className="w-3 h-3 ml-2 inline" />*/}
                  {/*    </div>*/}
                  {/*  )*/}
                  {/*) : (*/}
                  {/*  <div className="flex justify-center">{column.render("Header")}</div>*/}
                  {/*)}*/}
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div id="tbody" className="divide-y" {...getTableBodyProps()}>
        {rows.length === 0 ? (
          <div id="tr" className="text-center font-bold items-center p-3 w-max tblmax:w-full">
            {`No rows matching search query "${filterText}"`}
          </div>
        ) : (
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <div id="tr" className="items-center w-max tblmax:w-full" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <div id="td" className="m-0 p-2 text-center truncate" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default RevenueTable;
