import React, { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import TCaddieLogo from "../img/gtt-logo.png";
import { LogoutIcon, UserIcon } from "@heroicons/react/outline";
import AuthContext from "../context/AuthContext/authContext";

const Navbar = () => {
  const { logout, username, isAuthLoading } = useContext(AuthContext);
  return (
    <Disclosure as="nav" className="bg-white shadow-md">
      {({ open }) => (
        <>
          <div className="max-w-screen mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-20">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img className="header-logo" src={TCaddieLogo} alt="GolfTown Tournaments" />
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {!isAuthLoading && username && (
                    <h1 className="text-tcaddie_green text-l mr-6 font-medium">{username}</h1>
                  )}
                  {!isAuthLoading && (
                    <button
                      className="flex justify-center items-center w-24 bg-gt_green py-1 px-2 text-white text-md hover:bg-tcaddie_green focus:outline-none"
                      onClick={logout}
                    >
                      <span>Sign Out</span>
                    </button>
                  )}
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <button
                  className="flex p-1 rounded text-gt_green hover:bg-tcaddie_green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  onClick={logout}
                >
                  <LogoutIcon className="h-8 w-8 mx-1" aria-hidden="true" />
                  <span className="sr-only">Sign Out</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
