import React from "react";
import moment from "moment-timezone";
import { ExternalLinkIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const TableHeader = ({ text, isSorted, isSortedDesc, alignment }) => {
  if (isSorted) {
    if (isSortedDesc) {
      return (
        // Hacky fix cause I can't figure out why justify-end from tailwind isn't working
        // in prod...
        <div
          className={`flex justify-${alignment || "center"} items-center`}
          style={alignment === "end" ? { justifyContent: "flex-end" } : {}}
        >
          {text} <ChevronDownIcon className="w-3 h-3 ml-2 inline" />
        </div>
      );
    } else {
      // Hacky fix cause I can't figure out why justify-end from tailwind isn't working
      // in prod...
      return (
        <div
          className={`flex justify-${alignment || "center"} items-center`}
          style={alignment === "end" ? { justifyContent: "flex-end" } : {}}
        >
          {text} <ChevronUpIcon className="w-3 h-3 ml-2 inline" />
        </div>
      );
    }
  } else {
    // Hacky fix cause I can't figure out why justify-end from tailwind isn't working
    // in prod...
    return (
      <div
        className={`flex justify-${alignment || "center"} items-center`}
        style={alignment === "end" ? { justifyContent: "flex-end" } : {}}
      >
        {text}
      </div>
    );
  }
};

const columns = [
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="ID"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    accessor: "id",
    width: 50,
    minWidth: 50,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Create Date"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="start"
        />
      );
    },
    accessor: "created_at",
    Cell: ({ value }) => (
      <div className="text-left">
        {value ? moment(value).tz("America/Toronto").format("YYYY-MM-DD") : ""}
      </div>
    ),
    width: 105,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Event Name"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="start"
        />
      );
    },
    accessor: "event_name",
    minWidth: 200,
    width: 200,
    Cell: ({ value }) => <div className="text-left truncate">{value}</div>,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Event Date"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    accessor: "event_date",
    Cell: ({ cell: { value } }) =>
      value ? moment(value).tz("America/Toronto").format("YYYY-MM-DD") : "",
    width: 105,
    minWidth: 105,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Golf Club"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="start"
        />
      );
    },
    // Header: () => <div className="text-left m-0 p-0">Golf Club</div>,
    Cell: ({ value }) => <div className="text-left truncate">{value}</div>,
    accessor: "golf_club",
    width: 150,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Province"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    // Header: "Province",
    accessor: "province",
    width: 100,
    minWidth: 100,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Max Players"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    // Header: "Max Players",
    accessor: "max_players",
    width: 115,
    minWidth: 115,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="First Name"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    // Header: "First Name",
    accessor: "first_name",
    width: 105,
    minWidth: 105,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Last Name"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    // Header: "Last Name",
    accessor: "last_name",
    width: 105,
    minWidth: 105,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Email"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="start"
        />
      );
    },
    // Header: () => <div className="text-left">Email</div>,
    Cell: ({ value }) => (
      <div className="text-left">
        <a
          href={`mailto:${value}`}
          target="_blank"
          className="underline truncate hover:text-gt_green"
        >
          {value}
        </a>
      </div>
    ),
    accessor: "email",
    width: 185,
    minWidth: 185,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Revenue"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="end"
        />
      );
    },
    accessor: "revenue",
    Cell: ({ value }) => <div className="text-right truncate">{moneyFormatter.format(value)}</div>,
    width: 100,
    minWidth: 100,
  },
  {
    // Header: () => <div className="text-right">Balance</div>,
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Balance"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="end"
        />
      );
    },
    accessor: "balance",
    Cell: ({ value }) => <div className="text-right truncate">{moneyFormatter.format(value)}</div>,
    width: 100,
    minWidth: 100,
  },
  {
    Header: ({ column }) => {
      return (
        <TableHeader
          text="Website"
          isSorted={column.isSorted}
          isSortedDesc={column.isSortedDesc}
          alignment="center"
        />
      );
    },
    // Header: "Website",
    accessor: "website",
    Cell: ({ value }) => (
      <a
        className="flex justify-center text-gt_green hover:text-tcaddie_green"
        href={value}
        target="_blank"
      >
        <ExternalLinkIcon className="h-6 w-6 text-center" />
      </a>
    ),
    minWidth: 100,
    width: 100,
    disableSortBy: true,
  },
];

export default columns;
