import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AuthContext from "./authContext";
import Keycloak from "keycloak-js";
import axios from "axios";

const AuthState = (props) => {
  const [keycloak, setKeycloak] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [username, setUsername] = useState("");

  const initKeycloak = async () => {
    const keycloak = new Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
    });
    try {
      await keycloak.init({
        onLoad: "login-required",
      });
      setKeycloak(keycloak);
      axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
      // Intercept outgoing requests to update the token if necessary:
      axios.interceptors.request.use(
        async (config) => {
          const refreshed = await keycloak.updateToken(5);
          if (refreshed) {
            config.headers["Authorization"] = `Bearer ${keycloak.token}`;
            axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
            return config;
          } else {
            return config;
          }
        },
        (error) => {
          Promise.reject(error);
        }
      );
      setIsAuthLoading(false);
      setIsAuthenticated(true);
      setIsAuthError(false);
      setUsername(keycloak.tokenParsed["preferred_username"]);
    } catch {
      setIsAuthLoading(false);
      setIsAuthenticated(false);
      setIsAuthError(true);
      setUsername("");
    }
  };

  useEffect(() => {
    initKeycloak();
  }, []);

  const logout = () => {
    // eslint-disable-next-line
    delete axios.defaults.headers.common["Authorization"];
    keycloak.logout({ redirectUri: process.env.REACT_APP_LOGOUT_URL });
    setIsAuthenticated(false);
    setIsAuthLoading(true);
    setIsAuthError(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthLoading,
        isAuthenticated,
        isAuthError,
        username,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  // eslint-disable-next-line
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthState;
