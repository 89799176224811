import React from "react";
import Navbar from "../components/Navbar";
import { ScaleLoader } from "react-spinners";

const AuthLoadingLayout = () => {
  return (
    <div>
      <Navbar />
      <main className="flex items-center">
        <div className="max-w-screen max-h-screen  mx-auto  sm:px-6 lg:px-8">
          <div className="mt-60 px-4 py-2 sm:px-0">
            <ScaleLoader color="#144431" width={8} height={50} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AuthLoadingLayout;
