import React, { useEffect, useMemo, useState } from "react";
import download from "downloadjs";
import Navbar from "../components/Navbar";
import RevenueTable from "../components/RevenueTable";
import axios from "axios";
import cols from "../components/RevenueTable/columns";
import moment from "moment-timezone";
import { ScaleLoader } from "react-spinners";

export default function Example() {
  const [rowData, setRowData] = useState([]);
  const [dateRangeRowData, setDateRangeRowData] = useState([]);
  const [isRowDataLoading, setIsRowDataLoading] = useState(true);
  const [filterText, setFilterText] = useState(undefined);
  const [currentDateRange, setCurrentDateRange] = useState("upcoming");

  const onFilterChange = (e) => {
    if (e.target.value === "") setFilterText(undefined);
    else setFilterText(e.target.value);
  };

  const updateDateRange = () => {
    if (currentDateRange === "upcoming") {
      setDateRangeRowData(
        rowData.filter((row) => moment(row["event_date"]).isAfter(moment(), "day"))
      );
    } else if (currentDateRange === "current-year") {
      setDateRangeRowData(
        rowData.filter((row) => moment(row["event_date"]).isAfter(moment().startOf("year")))
      );
    } else if (currentDateRange === "all-time") {
      setDateRangeRowData(rowData);
    }
  };

  const onDateRangeChange = (e) => {
    if (e.target.value === currentDateRange) {
      return;
    } else {
      setCurrentDateRange(e.target.value);
    }
  };

  const getCSVExport = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/eventRevenue/csv`);
      download(res.data, "event-data.csv");
    } catch (err) {
      console.log(err);
    }
  };

  const getRevenueData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/eventRevenue`);
      setRowData(res.data.data);
      setIsRowDataLoading(false);
    } catch (err) {
      setRowData([]);
      setIsRowDataLoading(false);
    }
  };

  useEffect(() => {
    getRevenueData();
  }, []);

  useEffect(() => {
    updateDateRange();
  }, [currentDateRange, rowData]);

  const columns = useMemo(() => cols, []);
  const data = useMemo(() => dateRangeRowData, [dateRangeRowData]);

  console.log(`RowDataLength: ${rowData.length}`);
  console.log(`DateRangeDataLength: ${dateRangeRowData.length}`);

  return (
    <div className="h-screen">
      <Navbar />
      <main>
        <div className="max-w-screen max-h-full mx-auto  sm:px-6 lg:px-8">
          <div className="h-full px-4 py-2 pb-6 sm:px-0">
            {isRowDataLoading ? (
              <>
                <div className="flex items-center flex-row py-4">
                  <button
                    className={
                      "text-tcaddie_green text-xl font-medium pr-4 focus:outline-none " +
                      (currentDateRange === "upcoming" ? "underline" : "")
                    }
                    value="upcoming"
                    onClick={onDateRangeChange}
                  >
                    Upcoming Tournaments
                  </button>
                  |
                  <button
                    className={
                      "text-tcaddie_green text-xl font-medium px-4 focus:outline-none " +
                      (currentDateRange === "current-year" ? "underline" : "")
                    }
                    value="current-year"
                    onClick={onDateRangeChange}
                  >
                    Current Year
                  </button>
                  |
                  <button
                    className={
                      "text-tcaddie_green text-xl font-medium px-4 focus:outline-none " +
                      (currentDateRange === "all-time" ? "underline" : "")
                    }
                    value="all-time"
                    onClick={onDateRangeChange}
                  >
                    All Tournaments
                  </button>
                  <div className="flex-grow" />
                </div>
                <div className="flex justify-center pt-44">
                  <ScaleLoader color="#144431" width={8} height={50} />
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center flex-row py-4">
                  <button
                    className={
                      "text-tcaddie_green text-xl font-medium pr-4 focus:outline-none " +
                      (currentDateRange === "upcoming" ? "underline" : "")
                    }
                    value="upcoming"
                    onClick={onDateRangeChange}
                  >
                    Upcoming Tournaments
                  </button>
                  |
                  <button
                    className={
                      "text-tcaddie_green text-xl font-medium px-4 focus:outline-none " +
                      (currentDateRange === "current-year" ? "underline" : "")
                    }
                    value="current-year"
                    onClick={onDateRangeChange}
                  >
                    Current Year
                  </button>
                  |
                  <button
                    className={
                      "text-tcaddie_green text-xl font-medium px-4 focus:outline-none " +
                      (currentDateRange === "all-time" ? "underline" : "")
                    }
                    value="all-time"
                    onClick={onDateRangeChange}
                  >
                    All Tournaments
                  </button>
                  <div className="flex-grow" />
                  <input
                    type="text"
                    id="filter-text"
                    name="filter-text"
                    placeholder="Filter results..."
                    value={filterText}
                    onChange={onFilterChange}
                    className="text-gt_green p-1 mr-4 bg-transparent shadow-selected-input-light w-60 hover:shadow-selected-input-dark focus:outline-none focus:shadow-selected-input-dark placeholder-gt_green placeholder-opacity-60 hover:placeholder-tcaddie_green focus:placeholder-tcaddie_green focus:text-tcaddie_green hover:text-tcaddie_green"
                  />
                  <button
                    className="flex justify-center items-center w-24 bg-gt_green py-1 px-2 text-white text-md hover:bg-tcaddie_green focus:outline-none"
                    onClick={getCSVExport}
                  >
                    <span>Export</span>
                  </button>
                </div>
                <RevenueTable
                  columns={columns}
                  data={data}
                  filterText={filterText}
                  currentDateRange={currentDateRange}
                />
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
